import { Link } from '@inertiajs/react';
import Avatar from '../Avatar';
import ToolTip from '../ToolTip';
import { arrayToString } from '@/utils';
import EntryStatusBadge from '../EntryStatusBadge';
import PlatformBadge from '../PlatformBadge';
import { pluralize } from '@/utils';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import Features from './Partials/Features';

export default function ChampionshipCard({
	championship,
	compact = false
}) {
	let {
		id,
		name,
		cover_card,
		community,
		region,
		entry_status,
		platform,
		event_count,
		next_race,
		started_status,
		game,
		feature,
		entry_type
	} = championship;

	dayjs.extend(localizedFormat);

	return (
		<Link
			href={route('championships.show', id)}
			className="card bg-base-300 shadow-xl hover:-translate-y-2 duration-200 transition-all"
		>
			<PlatformBadge platform={platform} />
			<figure className="overflow-hidden rounded-t-2xl border-b-2 aspect-video relative">
				<div className="absolute inset-0 bg-transparent bg-gradient-to-t from-base-100 via-base-100/70 to-transparent"></div>
				<img src={cover_card} alt="Championship" />
				<div className="absolute bottom-0 flex flex-col w-full">
					{game.logo !== null && (
						<div className="flex justify-end p-4">
							<img
								className="w-2/4"
								src={game.logo}
								alt={game.name}
							/>
						</div>
					)}
				</div>
			</figure>

			<div className="card-body relative p-5">
				<div className="-mt-16 mb-4">
					<Avatar
						width="w-24"
						url={community.avatar}
						className="drop-shadow-lg"
					/>
				</div>

				<div className="absolute top-4 right-4">
					<EntryStatusBadge
						entryStatus={entry_status}
					/>
				</div>

				<div className="flex h-14 items-center">
					<h2 className="card-title block uppercase text-ellipsis line-clamp-2">
						{name}
					</h2>
				</div>

				{compact === false && (
					<div className="flex flex-col mt-4">
						{next_race &&
						<div className="flex items-center">
							<span className="material-icons-outlined text-primary text-4xl mr-2">
								event
							</span>
							<span className="text-gray-400 text-sm">
								{started_status === 'active' && 'Starts '}
								{dayjs(next_race).format(
									'LLL'
								)}
							</span>
						</div>
						}
						<div className="flex items-center">
							<span className="material-icons-outlined text-primary text-4xl mr-2">
								{(entry_type === 'team' ? 'group' : 'person')}
							</span>
							<span className="text-gray-400 text-sm capitalize">
								{entry_type}
							</span>
						</div>
						<div className="flex items-center">
							<span className="material-icons-outlined text-primary text-4xl mr-2">
								language
							</span>
							<span className="text-gray-400 text-sm">
								{arrayToString(region)}
							</span>
						</div>
						<div className="flex items-center">
							<span className="material-icons-outlined text-primary text-4xl mr-2">
								sports_score
							</span>
							<span className="text-gray-400 text-sm">
								{pluralize(
									event_count,
									'round',
									'rounds'
								)}
							</span>
						</div>
					</div>
				)}

				{feature !== null &&
				<Features
					commentated={feature.commentated}
					stewards={feature.stewards}
					liveStreamed={feature.liveStreamed}
					prizes={feature.prizes}
				/>
				}

				<div className="card-actions w-full">
					<button className="btn btn-primary btn-block">
						View Championship
					</button>
				</div>
			</div>
		</Link>
	);
}
